<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center" class="mb-5">
        <v-col cols="auto">
          <h1>Team Members' Capacity</h1>
        </v-col>
      </v-row>
      <v-card>
        <v-simple-table>
          <thead style="background-color: rgba(0, 0, 0, 0.08);">
            <tr>
              <th
                rowspan="2"
                style="border-bottom: thin solid rgba(0, 0, 0, 0.12);"
              ></th>
              <th
                v-for="(month, index) in months"
                :key="index"
                style="border-left: thin solid rgba(0, 0, 0, 0.12); border-bottom: thin solid rgba(0, 0, 0, 0.12);"
                colspan="5"
                class="text-subtitle-2 font-weight-bold"
              >
                {{ month }}
              </th>
            </tr>
            <tr>
              <th
                v-for="(title, index) in tableTitles"
                :key="index"
                style="border-left: thin solid rgba(0, 0, 0, 0.12);"
              >
                {{ title }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(teamMember, i1) in teamMembers" :key="i1">
              <th>{{ teamMember.member.full_name }}</th>
              <td
                v-for="(total, i2) in teamMember.month_breakdown"
                :key="i2"
                style="border-left: thin solid rgba(0, 0, 0, 0.12);"
              >
                {{ total.text }}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr style="background-color: rgba(0, 0, 0, 0.08);">
              <th style="border-top: thin solid rgba(0, 0, 0, 0.12);">
                Totals
              </th>
              <td
                v-for="(total, index) in totals"
                :key="index"
                style="border-left: thin solid rgba(0, 0, 0, 0.12); border-top: thin solid rgba(0, 0, 0, 0.12);"
              >
                {{ total }}
              </td>
            </tr>
            <tr style="background-color: rgba(0, 0, 0, 0.08);">
              <th style="border-top: thin solid rgba(0, 0, 0, 0.12);">
                Expected Total
              </th>
              <td
                v-for="(total, index) in expectations"
                :key="index"
                colspan="5"
                style="border-left: thin solid rgba(0, 0, 0, 0.12); border-top: thin solid rgba(0, 0, 0, 0.12);"
              >
                {{ total }}
              </td>
            </tr>
          </tfoot>
        </v-simple-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: "Team",
          disabled: false,
          to: { name: "module-team" },
          exact: true,
        },
        {
          text: "Resourcing",
          disabled: true,
        },
      ],
    };
  },

  computed: {
    months() {
      return this.$store.getters["team/timetastic/getCapacityMonths"];
    },

    teamMembers() {
      return this.$store.getters["team/timetastic/getCapacityUsers"];
    },

    tableTitles() {
      return this.$store.getters["team/timetastic/getTableTitles"];
    },

    totals() {
      return this.$store.getters["team/timetastic/getCapacityTotals"];
    },

    expectations() {
      return this.$store.getters["team/timetastic/getCapacityExpectations"];
    },
  },
};
</script>
